import React, { useEffect, useReducer } from "react";

import {
    currentCrumb,
    FeatureMessage,
    FeatureMessageReady,
    FeatureMessageStatus,
    labsUrl,
    linkCrumb,
    Menu,
    Page,
    PageHeader,
    Segment,
    SegmentHeader,
} from "@ingka-livlig/frontend-lib";
import Checkbox from "@ingka/checkbox";
import { Trans, useLingui } from "@lingui/react/macro";
import { AppSettings } from "./AppSettings";
import styles from "./Features.module.css";

export type Feature = keyof typeof features;

/**
 * Update this whenever you add or remove a feature toggle.
 */
const features = { "public-demo": "Public demo mode" };

export function isFeatureEnabled(feature: Feature) {
    const featureKey = `feature-${feature}`;
    return localStorage.getItem(featureKey) !== null;
}

/**
 * A page which shows available feature toggles. Update this whenever you add or remove a feature toggle.
 *
 * @constructor
 */
export default function Feature() {
    const { t } = useLingui();

    const [, forceUpdate] = useReducer((x) => x + 1, 0);

    function renderFeature(feature: Feature, name: string) {
        const featureKey = `feature-${feature}`;
        const isEnabled = localStorage.getItem(featureKey) !== null;

        // Event listener for the 'toggleSwitch' event
        useEffect(() => {
            //Check if we're inside an IFrame. If se we need to allow for communication.
            if (window.location !== window.parent.location) {
                const handleMessages = (event: MessageEvent) => {
                    if (event.origin !== labsUrl()) {
                        return;
                    }
                    const data = event.data as FeatureMessage;
                    if (data != null) {
                        if (data.tag === "livlig:get") {
                            const message: FeatureMessageStatus = {
                                tag: "livlig:status",
                                // @ts-ignore
                                values: new Map(Object.keys(features).map((key) => [key, isFeatureEnabled(key)])),
                            };
                            window.top?.postMessage(message, event.origin);
                        } else if (data.tag === "livlig:set") {
                            if (data.value) {
                                localStorage.setItem(`feature-${data.key}`, "1");
                            } else {
                                localStorage.removeItem(`feature-${data.key}`);
                            }
                        }
                    }
                };

                window.addEventListener("message", handleMessages);

                //Once we've set up our listeners we need to inform the containing window that we're ready.
                const readyMessage: FeatureMessageReady = { tag: "livlig:ready" };
                window.parent.postMessage(readyMessage, "*");
                return () => {
                    window.removeEventListener("message", handleMessages);
                };
            }
        }, []);

        return (
            <li>
                <Checkbox
                    id={feature}
                    key={feature}
                    checked={isEnabled}
                    value={""}
                    label={name}
                    onChange={(event) => {
                        if (event.target.checked) {
                            localStorage.setItem(featureKey, "1");
                        } else {
                            localStorage.removeItem(featureKey);
                        }
                        forceUpdate();
                    }}
                />
            </li>
        );
    }

    return (
        <Page
            title={`Feature toggles`}
            headerElement={
                <PageHeader headerText="Livlig" actionElement={[<AppSettings />]}>
                    <Menu currentApp="landing" />
                </PageHeader>
            }
            breadcrumbItems={[linkCrumb(t`Livlig`, "/"), currentCrumb(`Feature toggles`)]}
        >
            <SegmentHeader>
                <Trans>Feature toggles</Trans>
            </SegmentHeader>

            <Segment variant={"primary"} padded>
                <ul className={styles.featureList}>
                    {Object.entries(features).map(([key, name]) => renderFeature(key as Feature, name))}
                </ul>{" "}
            </Segment>
        </Page>
    );
}
