import React from "react";

import styles from "./Logo.module.css";
import { Trans } from "@lingui/react/macro";

const Logo: React.FC<{ className?: string }> = ({ className }) => (
    <div className={`${styles.logo} ${className ? className : ""}`}>
        <h1>Livlig</h1>
        <p>
            <Trans>Real-time operational insights</Trans>
        </p>
    </div>
);

export default Logo;
