import React, { useState } from "react";
import styles from "./ChooseLocation.module.css";
import {
    Center,
    extractSelectedLanguage,
    Footer,
    globalLocation,
    LivligButton,
    LoadingIndicator,
    locales,
    LocationScope,
    LocationScopeWithMetadata,
    persistLanguageSetting,
    Splash,
    unlessSuccessful,
    useApiCall,
} from "@ingka-livlig/frontend-lib";
import { Trans } from "@lingui/react/macro";
import { Link, Navigate } from "react-router-dom";
import { useMetadataAPI } from "./metadataAPI";
import { useUserAPI } from "./userAPI";
import { MapChart, useMapRates, ZoomLevel } from "@ingka-livlig/frontend-map";
import { useLocationsAPI } from "./locationsAPI";
import { extractDomainTLD } from "./domainUtil";
import Logo from "./Logo";
import { StringParam, useQueryParam } from "use-query-params";
import { localeActivate } from "./i18n";
import { useSalesStreams } from "./salesAPI";
import { LocationSelector } from "./SelectLocation";

export function InitialRedirect() {
    const userApi = useUserAPI();
    const userLocationReq = useApiCall(userApi.userLocationDetails);

    switch (userLocationReq.tag) {
        case "loading":
            return (
                <div className={styles.page}>
                    <Splash />
                </div>
            );
        case "success":
            if (userLocationReq.value.stored) {
                window.location.href = constructSalesUrl(userLocationReq.value.stored);
                return (
                    <div className={styles.page}>
                        <Splash />
                    </div>
                );
            } else {
                return <Navigate to="/choose-location" replace />;
            }
        // For error cases we default to showing the location chooser to avoid getting the user stuck.
        case "not-found":
        case "error":
            return <Navigate to="/choose-location" replace />;
    }
}

export function ChooseLocation() {
    const locationsApi = useLocationsAPI();
    const userApi = useUserAPI();
    const countries = unlessSuccessful(useApiCall(useMetadataAPI().countries), null);
    const userLocationReq = useApiCall(userApi.userLocationDetails);
    const initialLocation =
        userLocationReq.tag === "success" ? userLocationReq.value.stored || userLocationReq.value.guessed : null;
    const [location, setLocation] = useState<LocationScopeWithMetadata | null>(initialLocation);
    const [returnUrl] = useQueryParam("ret", StringParam);

    const [events, minuteStats, secondStats] = useSalesStreams(location || globalLocation);
    const rates = useMapRates(location?.scope, minuteStats, secondStats, location?.currency.name || "Euro");

    const browserLang = navigator.language.slice(0, 2);
    const lang = extractSelectedLanguage();

    const browseLocale = locales[browserLang as keyof typeof locales];

    const languageAlert =
        browserLang !== "en" && browserLang !== lang && browseLocale ? (
            <LocaleAlert locale={browserLang} localNameOfLocale={browseLocale} />
        ) : null;

    let mapZoom: ZoomLevel | undefined = undefined;
    switch (location?.scope) {
        case "Country":
            mapZoom = { scope: "Country", countryCode: location.countryCode };
            break;
        case "SellingUnit":
            mapZoom = {
                scope: "SellingUnit",
                countryCode: location.countryCode,
                sellingUnitCode: location?.sellingUnitCode,
                sellingUnitLat: location?.sellingUnitLocation.lat,
                sellingUnitLng: location?.sellingUnitLocation.lng,
            };
            break;
    }

    async function goClicked() {
        const storedLocation = userLocationReq.tag === "success" ? userLocationReq.value.stored : null;
        if (location) {
            if (!isSame(location, storedLocation)) {
                switch (location.scope) {
                    case "Global":
                        await userApi.setUserLocation({ scope: "Global" });
                        break;
                    case "Country":
                        await userApi.setUserLocation({ scope: "Country", countryCode: location.countryCode });
                        break;
                    case "SellingUnit":
                        await userApi.setUserLocation({
                            scope: "SellingUnit",
                            countryCode: location.countryCode,
                            sellingUnitCode: location.sellingUnitCode,
                        });
                        break;
                }
            }
            // If return URL specified as query param then navigate to it
            if (returnUrl) {
                window.location.href = returnUrl;
            } else {
                window.location.href = constructSalesUrl(location);
            }
        }
    }

    function renderLocationSelector() {
        if (userLocationReq.tag === "success" && countries) {
            return (
                <LocationSelector
                    initialLocale={lang}
                    initialLocation={initialLocation}
                    countries={countries.countries}
                    onLanguageChange={(locale) => {
                        if (locale) {
                            persistLanguageSetting(locale);
                            localeActivate(locale);
                        }
                    }}
                    onLocationChange={(selection) => {
                        selection && locationsApi.getMetadata(selection).then(setLocation);
                    }}
                    className={styles.selectLocation}
                />
            );
        } else {
            return <LoadingIndicator />;
        }
    }

    return (
        <div className={styles.page}>
            <LogoOverlay />
            <div className={styles.mapSection}>
                <Link to="/map">
                    <MapChart className={styles.mapChart} zoom={mapZoom} eventStream={events} rates={rates} />
                </Link>
            </div>
            <div className={styles.selectSection}>
                <div className={styles.selectContainer}>
                    {languageAlert}
                    <p>
                        <Trans>Where do you work?</Trans>
                    </p>
                    {renderLocationSelector()}
                    <LivligButton type="primary" disabled={location === null} onClick={goClicked}>
                        <Trans>Go</Trans>
                    </LivligButton>
                </div>
            </div>
            <div className={styles.briefSection}>
                <h2>
                    <Trans>Meet Livlig</Trans>
                </h2>
                <p>
                    <Trans>
                        A web application that puts real-time, actionable sales data insights at your fingertips,
                        enabling data-driven decisions to improve our everyday business.
                    </Trans>
                </p>
                <p>
                    <Trans>
                        With Livlig, you can follow any article in your store and see how many units of it you’ve sold
                        at any point, each day. You can also compare the sales of today with other days, and with how
                        you’re doing compared to other stores.
                    </Trans>
                </p>
            </div>
            <span className={styles.whiteFooter}>
                <Footer />
            </span>
        </div>
    );
}

// Show a small alert suggesting the browser locale if it's not yet chosen. Does not fire when browser locale is English.
const LocaleAlert: React.FC<{ locale: string; localNameOfLocale: string }> = ({ locale, localNameOfLocale }) => {
    function extractLocalLocaleName() {
        return localNameOfLocale;
    }

    return (
        <React.Fragment>
            <Center>
                <p>
                    <Trans>Language was detected</Trans>
                </p>
                <Trans>Do you want to switch language to {extractLocalLocaleName()}?</Trans>
            </Center>
            <Center>
                <LivligButton
                    type={"primary"}
                    onClick={() => {
                        persistLanguageSetting(locale);
                        localeActivate(locale);
                    }}
                >
                    <Trans>Yes, please</Trans>
                </LivligButton>
            </Center>
        </React.Fragment>
    );
};

function LogoOverlay() {
    return (
        <div className={styles.logoOverlay}>
            <Logo />
        </div>
    );
}

function isSame(location1: LocationScope, location2: LocationScope | null): boolean {
    if (location2) {
        switch (location1.scope) {
            case "Global":
                return location2.scope === "Global";
            case "Country":
                return location2.scope === "Country" && location1.countryCode === location2.countryCode;
            case "SellingUnit":
                return location2.scope === "SellingUnit" && location1.sellingUnitCode === location2.sellingUnitCode;
        }
    } else {
        return false;
    }
}

function constructSalesUrl(location: LocationScope): string {
    function baseUrl() {
        if (window.location.hostname === "localhost") {
            return `//localhost:3000`;
        } else {
            const domainTLD = extractDomainTLD();
            return `//item-sales.livlig.ingka.${domainTLD}`;
        }
    }

    switch (location.scope) {
        case "Global":
            return `${baseUrl()}/locations/${location.scope}`;
        case "Country":
            return `${baseUrl()}/locations/${location.scope}/${location.countryCode}`;
        case "SellingUnit":
            return `${baseUrl()}/locations/${location.scope}/${location.sellingUnitCode}`;
    }
}
