import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import React from "react";
import { AppBase, TrackedRoute, TranslatePage, useEvents, UserNavigationEvent } from "@ingka-livlig/frontend-lib";
import { StringParam, useQueryParam } from "use-query-params";
import { MapView } from "./MapView";
import { Map3DView } from "./Map3DView";
import { localeActivate } from "./i18n";
import { withStore } from "react-context-hook";
import NotFound from "./NotFound";
import { useUserAPI } from "./userAPI";
import { ChooseLocation, InitialRedirect } from "./ChooseLocation";
import { Training } from "./Training";
import Features from "./Features";
import * as englishMessages from "./locales/en/messages.mjs";
import { RemoteLogin, RemoteLoginValidate } from "./RemoteLogin";

/**
 * Just redirect the browser based on what's in the "goto" query param.
 * @constructor
 */
function GotoPage() {
    const [goto] = useQueryParam("goto", StringParam);
    if (goto) {
        window.location.replace(goto);
        return null;
    } else {
        return <Navigate to={"/"} replace />;
    }
}

const Translate = () => {
    const api = useUserAPI();
    return (
        <TranslatePage
            recordTranslation={api.recordTranslation}
            fetchEnglishTransations={() => englishMessages.messages}
        />
    );
};

function App() {
    const events = useEvents<UserNavigationEvent>(useUserAPI());

    const router = createBrowserRouter([
        {
            element: <AppBase localeActivate={localeActivate} appName={"Landing Page"} />,
            children: [
                {
                    path: "/",
                    element: (
                        <TrackedRoute routeName="Root" events={events}>
                            <InitialRedirect />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/choose-location",
                    element: (
                        <TrackedRoute routeName="ChooseLocation" events={events}>
                            <ChooseLocation />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/translate",
                    element: (
                        <TrackedRoute routeName="Translate" events={events}>
                            <Translate />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/map",
                    element: (
                        <TrackedRoute routeName="Map" events={events}>
                            <MapView />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/map3d",
                    element: (
                        <TrackedRoute routeName="Map3D" events={events}>
                            <Map3DView />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/goto-page",
                    element: <GotoPage />,
                },
                {
                    path: "/training",
                    element: (
                        <TrackedRoute routeName="Training" events={events}>
                            <Training />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/training/lesson/:lessonId",
                    element: (
                        <TrackedRoute routeName="Lesson" events={events}>
                            <Training />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/features",
                    element: (
                        <TrackedRoute routeName="Features" events={events}>
                            <Features />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "/qr",
                    element: <RemoteLogin />,
                },
                {
                    path: "/qr/:sessionId",
                    element: (
                        <TrackedRoute routeName="RemoteLogin" events={events}>
                            <RemoteLoginValidate />
                        </TrackedRoute>
                    ),
                },
                {
                    path: "*",
                    element: (
                        <TrackedRoute routeName="NotFound" events={events}>
                            <NotFound />
                        </TrackedRoute>
                    ),
                },
            ],
        },
    ]);
    return <RouterProvider router={router} />;
}

export default withStore(App, {});
