import { Menu, NotFound as NF, Page, PageHeader } from "@ingka-livlig/frontend-lib";
import { AppSettings } from "./AppSettings";
import { useLingui } from "@lingui/react/macro";

export default function NotFound() {
    const { t } = useLingui();

    return (
        <Page
            title={t`Home`}
            headerElement={
                <PageHeader headerText="Livlig" actionElement={<AppSettings />}>
                    <Menu currentApp="landing" />
                </PageHeader>
            }
        >
            <NF />
        </Page>
    );
}
